import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { storeToken } from "../../Data/services/localStorageService";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";

export default function Login({ setPage }) {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  // const pagename = useSelector((state) => state.pagename.pagename);

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const handleLogin = async (data) => {
    data.device_type = "web";
    data.device_token = "123";
    // storeToken("token");
    // navigate(WEB_ROUTES.DASHBOARD);
    try {
      const response = await loginUser(data);

      console.log(response);

      if (response.error && response.error.status >= 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
      }

      if (!response.error && response.data.code === 200) {
        storeToken(response.data.data.api_token);
        SuccessToast(response?.data?.message);
        navigate("/"+WEB_ROUTES.DASHBOARD);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout>
      {errors
        ? errors.email?.message
          ? Toast(errors.email?.message, "error")
          : Toast(errors.password?.message, "error")
        : null}
      {
        // <Title text="Welcome to" classes="mb-2 fs-4" />
      }
      <div className="card rounded-4 shadow-sm w-100 p-3">
        <SubTitle text="Admin Panel" classes="mb-2 black-text text-center" />

        <Form onSubmit={handleSubmit(handleLogin)} className="w-100">
          <Controller
            control={control}
            name="email"
            // rules={{
            //   required: "Email is required.",
            //   pattern: {
            //     value: /\S+@\S+\.\S+/,
            //     message: "Enter a valid email address.",
            //   },
            // }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup
                className="mb-3"
                // style={{
                //   width: "300px",
                // }}
              >
                <CustomInput
                  placeholder="Email"
                  type="email"
                  onChange={onChange}
                  value={value}
                />
              </InputGroup>
            )}
          />
          <Controller
            control={control}
            name="password"
            // rules={{
            //   required: "Password is required.",
            //   minLength: {
            //     value: 3,
            //     message: "Minimum 3 characters long.",
            //   },
            // }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup
                className="mb-3"
                // style={{
                //   width: "300px",
                // }}
              >
                <CustomInput
                  type="password"
                  placeholder="Password"
                  onChange={onChange}
                  value={value}
                />
              </InputGroup>
            )}
          />
          <Button
            text={isLoading ? <BeatLoader size={10} /> : "Login"}
            classes={`mt-3 mb-2 ${isLoading ? "bg-dark-subtle" : ""}`}
            type="submit"
            // onClick={handleSubmit}
            disabled={isLoading}
          />
        </Form>
        <Description
          classes="ms-auto cursor-pointer gray-text"
          text="Forgot Password ?"
          onClick={handleForgotPasswordClick}
        />
      </div>
    </AccountLayout>
  );
}
