import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Button, SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/mcq_management.module.scss";
import { useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import EditPriceGuessingModal from "../../Components/Modals/EditPriceGuessingModal";
import RecordNotFound from "../../Components/RecordNotFound";
import {
  useAddQuestionMutation,
  useDeleteQuestionMutation,
  useGetAllPriceGuessingQuery,
  useUpdateQuestionMutation,
} from "../../Data/services/questionsApi";

const PriceGuessingManagementScreen = ({ setPage }) => {
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPriceGuessingQuery({ refetchOnMountOrArgChange: true });

  var priceGuessingData = useSelector((state) => state.questions.priceGuessing);

  useEffect(() => {
    setRowData(priceGuessingData);
  }, [priceGuessingData]);

  // const dummyData = [
  //   {
  //     id: 1,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: true },
  //       { option: "option 4", is_correct: false },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: true },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: false },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: true },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: false },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 6,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 7,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 8,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 9,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 10,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 11,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  //   {
  //     id: 12,
  //     module_type: 1,
  //     question: "This is question number 1 for type multiple.",
  //     description: "Devil Times Five (a.k.a. Peopletoys)",
  //     time_limit: "30s",
  //     score: 40.5,
  //     actual_price: 12000,
  //     image_url: "https://fakeimg.pl/600x400",
  //     status: 1,
  //     options: [
  //       { option: "option 1", is_correct: false },
  //       { option: "option 2", is_correct: false },
  //       { option: "option 3", is_correct: false },
  //       { option: "option 4", is_correct: true },
  //     ],
  //   },
  // ];

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const [deleteQuestion, { isLoading: isDeleting }] =
    useDeleteQuestionMutation();

  const deleteData = async () => {
    const id = editedData.id;

    try {
      const { data } = await deleteQuestion(id);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [addQuestion, { isLoading: addLoading }] = useAddQuestionMutation();

  const handleAdd = async (data) => {
    const formData = new FormData();
    formData.append("module_type", data.module_type);
    formData.append("question", data.question);
    formData.append("description", data.description);
    formData.append("time_limit", Number(data.time_limit));
    formData.append("status", data.status);
    formData.append("image", data.image);
    formData.append("price", data.price);
    formData.append(`options[0][option]`, data?.min);
    formData.append(`options[0][is_correct]`, 0);
    formData.append(`options[1][option]`, data?.max);
    formData.append(`options[1][is_correct]`, 0);

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      const response = await addQuestion(formData);
      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [updateQuestion, { isLoading: updateLoading }] =
    useUpdateQuestionMutation();

  const handleUpdate = async (data) => {
    const formData = new FormData();
    formData.append("module_type", data.module_type);
    formData.append("question", data.question);
    formData.append("description", data.description);
    formData.append("time_limit", Number(data.time_limit));
    formData.append("status", data.status);
    formData.append("image", data.image);
    formData.append("price", data.price);
    formData.append(`options[0][option]`, data?.min);
    formData.append(`options[0][is_correct]`, 0);
    formData.append(`options[1][option]`, data?.max);
    formData.append(`options[1][is_correct]`, 0);

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      const response = await updateQuestion({
        modifiedData: formData,
        id: editedData.id,
      });
      // console.log(response);

      if (response.data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    return isLoading ? <ShimmerTable row={5} col={4} /> : <RecordNotFound />;
  }

  const columns = [
    {
      dataField: "question",
      text: "Questions",
      sort: true,
      headerAlign: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerAlign: "center",
      sort: true,
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "image_url",
      text: "Image",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        if (row.image_url) {
          return (
            <img
              alt="Image not found"
              src={row.image_url}
              className="rounded-4"
              style={{
                width: "150px",
                height: "110px",
                objectFit: "cover",
              }}
            />
          );
        }
      },
    },
    // {
    //   dataField: "time_limit",
    //   text: "Time Left",
    //   sort: true,
    //   headerAlign: "center",
    //   align: "center",

    //   headerStyle: {
    //     width: "100px",
    //   },
    // },
    {
      dataField: "",
      text: "Min",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return row?.question_options[0]?.option;
      },
    },
    {
      dataField: "",
      text: "Max",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return row?.question_options[1]?.option;
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "active") {
          return <div className={styles.active}>Active</div>;
        } else {
          return <div className={styles.block}>InActive</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      )}

      {editModal && (
        <EditPriceGuessingModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
        />
      )}

      <div className={styles.headerContainer}>
        <SubTitle text={"Price Guessing Management"} classes="black-text" />

        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} fs-6 px-4`}
              text="Add Question"
              onClick={handleAddUser}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={rowData || []} columns={columns} indication={indication} />
      </div>
    </div>
  );
};

export default PriceGuessingManagementScreen;
