import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { FaDatabase } from "react-icons/fa";
import { useSelector } from "react-redux";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { ROUTES } from "./constants";

export default function Dashboard({ setPage }) {
  const handleViewLiveMatches = () => {
    setPage(ROUTES.LIVE_MATCHES);
  };

  const handleViewTournament = () => {
    setPage(ROUTES.TOURNAMENTS);
  };

  var data = useSelector((state) => state.dashboard.data);
  console.log(data);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="black-text" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        {
          //   <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          //   <DashboardCard
          //     bg1={"#FFD242"}
          //     bg2={"#efefef"}
          //     icon={<FaDatabase color="#212121" />}
          //     // number={data == null ? 0 : data.post_counts}
          //     number={10}
          //     text={"Numbers of Questionnaire"}
          //   />
          // </Col>
        }
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#FFD242"}
            bg2={"#efefef"}
            icon={<FaDatabase color="#212121" />}
            number={data == null ? 0 : data.total_price_guessing}
            // number={10}
            text={"Numbers of Price Guessing"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={6} md={6} sm={6}>
          <DashboardCard
            bg1={"#FFD242"}
            bg2={"#efefef"}
            icon={<FaDatabase color="#212121" />}
            number={data == null ? 0 : data.total_mcqs}
            // number={10}
            text={"Numbers of MCQ's"}
          />
        </Col>
        {/* <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#32CD32"}
            bg2={"#90EE90"}
            icon={<FaDatabase color="#32CD32" />}
            number={data == null ? 0 : data.communities}
            text={"Numbers of Communities"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#9932CC"}
            bg2={"#9370DB"}
            icon={<FaDatabase color="#9932CC" />}
            number={data == null ? 0 : data.pet_community.count}
            text={"Missing Pets Communities"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#008080"}
            bg2={"#00FFFF"}
            icon={<FaDatabase color="#008080" />}
            number={data == null ? 0 : data.person_community.count}
            text={"Missing Person Communities"}
          />
        </Col> */}
      </Row>
    </div>
  );
}
