import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { FaUserEdit } from "react-icons/fa";

import { MdDeleteForever } from "react-icons/md";

import { Button, SubTitle } from "../../Components/CustomComponents";

import DeleteModal from "../../Components/Modals/DeleteModal";

import Table from "../../Components/Table/Table";

import Toast, { SuccessToast } from "../../Hooks/useToast";

import styles from "./styles/mcq_management.module.scss";

import { useSelector } from "react-redux";

import { ShimmerTable } from "react-shimmer-effects";

import RecordNotFound from "../../Components/RecordNotFound";

import EditWheelOptionModal from "../../Components/Modals/EditWheelOptionModal";
import {
  useAddQuestionSpinMutation,
  useDeleteQuestionSpinMutation,
  useGetAllWheelOptionsQuery,
  useGetShopifyProductsQuery,
  useUpdateQuestionSpinMutation,
} from "../../Data/services/spinWheelManagementApi";

const OPTIONS = {
  fixed_amount: "Fixed Amount",
  percentage: "Percentage",
};

const SpinWheelOption = ({ setPage }) => {
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { refetch, isLoading } = useGetAllWheelOptionsQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: shopifyProduct } = useGetShopifyProductsQuery({
    refetchOnMountOrArgChange: true,
  });

  var mcqData = useSelector((state) => state.questions.wheel_options);

  useEffect(() => {
    setRowData(mcqData);
  }, [mcqData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const [deleteQuestionSpin, { isLoading: isDeleting }] =
    useDeleteQuestionSpinMutation();

  const deleteData = async () => {
    const id = editedData.id;

    try {
      const { data } = await deleteQuestionSpin(id);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [addQuestionSpin, { isLoading: addLoading }] =
    useAddQuestionSpinMutation();

  const handleAdd = async (data) => {
    try {
      const response = await addQuestionSpin({ options: data });

      if (response?.error?.data?.code == 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response?.data?.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [updateQuestionSpin, { isLoading: updateLoading }] =
    useUpdateQuestionSpinMutation();

  const handleUpdate = async (data) => {
    try {
      const response = await updateQuestionSpin({
        data,
        id: editedData.id,
      });

      if (response?.error?.data?.code == 400) {
        Toast(response?.error?.data?.message, "error");
        return;
      }

      if (response.data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(response.data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    return isLoading ? <ShimmerTable row={5} col={4} /> : <RecordNotFound />;
  }

  const columns = [
    {
      dataField: "discount_type",
      text: "Discount Type",
      sort: false,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return <div>{OPTIONS[row?.discount_type]}</div>;
      },
    },
    {
      dataField: "option",
      text: "Option",
      sort: false,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "product_title",
      text: "Product Title",
      sort: false,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "product_image",
      text: "Product Image",
      sort: false,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.product_image) {
          return (
            <img
              alt="Image not found"
              src={row.product_image}
              className="rounded-4"
              style={{
                width: "150px",
                height: "110px",
                objectFit: "cover",
              }}
            />
          );
        }
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "active") {
          return <div className={styles.active}> Active </div>;
        } else {
          return <div className={styles.block}>In active</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center black-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      )}

      {editModal && (
        <EditWheelOptionModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
          shopifyProduct={shopifyProduct}
          totalRecordLength={rowData.length}
        />
      )}

      <div className={styles.headerContainer}>
        <SubTitle text={"Spin Wheel Management"} classes="black-text" />

        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} fs-6 px-4`}
              text="Add Spin Wheel Option"
              onClick={handleAddUser}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={rowData || []} columns={columns} indication={indication} />
      </div>
    </div>
  );
};

export default SpinWheelOption;
