import { createSlice } from "@reduxjs/toolkit";
import { sessionApi } from "../services/sessionApi";

const initialState = {
  data: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        sessionApi.endpoints.getSessions.matchFulfilled,
        (state, { payload }) => {
          state.data = payload.data;
        }
      )
      .addMatcher(sessionApi.endpoints.getSessions.matchRejected, (state) => {
        state.data = null;
      });
  },
});

export const { setDashboardInfo, unsetDashboardInfo } = sessionSlice.actions;

export default sessionSlice.reducer;
