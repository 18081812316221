import { baseApi } from "./baseApi";

export const spinWheelManagementApi = baseApi.injectEndpoints({
  reducerPath: "spinWheelManagementApi",
  endpoints: (builder) => ({
    addQuestionSpin: builder.mutation({
      query: (formData) => {
        return {
          url: "api/spin-option/store",
          method: "POST",
          body: formData,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    updateQuestionSpin: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `api/spin-option/update/${id}`,
          method: "PUT",
          body: { options: data },
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    deleteQuestionSpin: builder.mutation({
      query: (id) => {
        return {
          url: `api/spin-option/delete/${id}`,
          method: "DELETE",
          // body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    getAllWheelOptions: builder.query({
      query: () => {
        return {
          url: `api/spin-option/get`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    getShopifyProducts: builder.query({
      query: () => {
        return {
          url: `api/user/shopify/get-products`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
  }),
});

export const {
  useAddQuestionSpinMutation,
  useUpdateQuestionSpinMutation,
  useDeleteQuestionSpinMutation,
  useGetAllWheelOptionsQuery,
  useGetShopifyProductsQuery,
} = spinWheelManagementApi;
