import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input } from "../CustomComponents";
import styles from "../CustomComponents/index.module.css";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editMcqModal.module.scss";

const statusList = [
  { label: "Active", value: "active" },
  { label: "In active", value: "inactive" },
  { label: "Completed", value: "completed" },
];

const EditSessionModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      start_time: "",
      end_time: "",
      date: "",
      status: "active",
    },
  });

  const [flag, setFlag] = useState(null);

  // console.log(data);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      let dateFormat = moment(data?.date).format("YYYY-MM-DD");
      try {
        setValue("name", data?.name);
        setValue("description", data?.description);
        setValue(
          "start_time",
          moment.utc(`${dateFormat} ${data?.start_time}`).local().format("HH:mm:ss")
        );
        setValue(
          "end_time",
          moment.utc(`${dateFormat} ${data?.end_time}`).local().format("HH:mm:ss")
        );
        setValue("date", dateFormat);
        setValue("status", data?.status);

        setFlag(!flag);
      } catch (e) {
        console.log("something went wrong !");
      }
    }
  }, [data]);

  const onSubmit = async (record) => {
    try {
      const body = {
        ...record,
        start_time: moment(record.start_time, "HH:mm:ss")
          .utc()
          .format("HH:mm:ss"),
        end_time: moment(record.end_time, "HH:mm:ss").utc().format("HH:mm:ss"),
        date: record?.date,
      };

      if (!_.isEmpty(data)) {
        handleUpdate(body);
      } else {
        handleAdd(body);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">
              {data ? "Edit Session" : "Add Session"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Session Name</p>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: "Session name is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 "
                        type="text"
                        placeholder="Enter question"
                        value={value}
                        onChange={onChange}
                        name="name"
                      />
                    )}
                  />

                  {errors?.name?.message && (
                    <p className="m-0 text-danger">{errors?.name?.message}</p>
                  )}
                </Col>

                <Col md={12} sm={12} className="mb-3">
                  <p className="m-0 black-text">Description</p>
                  <Controller
                    control={control}
                    name="description"
                    rules={{
                      required: "Description is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2"
                        type="text"
                        placeholder="Enter description"
                        value={value}
                        onChange={onChange}
                        name="description"
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <p className="m-0 text-danger">
                      {errors?.description?.message}
                    </p>
                  )}
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session Start Time</p>
                  <Controller
                    control={control}
                    name="start_time"
                    rules={{
                      required: "Start time is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="time"
                        placeholder="Enter Start time"
                        value={value}
                        onChange={onChange}
                        name="start_time"
                      />
                    )}
                  />
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session End Time</p>
                  <Controller
                    control={control}
                    name="end_time"
                    rules={{
                      required: "Start time is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="time"
                        placeholder="Enter End time"
                        value={value}
                        onChange={onChange}
                        name="end_time"
                      />
                    )}
                  />
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Session Date</p>
                  {/* <CustomDateTimePicker
                    classes="mb-3"
                    value={getValues("date")}
                    onChange={(e) => handleChangeStartEndTime("date", e)}
                  /> */}

                  <Controller
                    control={control}
                    name="date"
                    rules={{
                      required: "Date is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mb-2"
                        type="date"
                        placeholder="Enter End time"
                        value={value}
                        onChange={onChange}
                        name="date"
                      />
                    )}
                  />
                </Col>

                <Col md={12}>
                  <p className="m-0 black-text">Status</p>
                  <Controller
                    control={control}
                    name="status"
                    rules={{
                      required: "Status is required.",
                    }}
                    value={getValues("status")}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className={`${styles.Input} w-100`}
                        onChange={onChange}
                        value={getValues("status")}
                      >
                        {!!statusList?.length &&
                          statusList.map(({ label, value }, index) => (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                  {errors?.description?.message && (
                    <p className="m-0 text-danger">
                      {errors?.description?.message}
                    </p>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditSessionModal;

{
  /* <Form onSubmit={handleSubmit(handleAddDateTimeInData)}>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Session Name</p>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: "Session name is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter question"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />

                      {errors?.name?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.name?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <p className="m-0 black-text">Description</p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{
                          required: "Description is required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {errors?.description?.message ? (
                        <p className="m-0 text-danger text-center">
                          {errors?.description?.message}
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}></Col>
                  </Row>
                </Col>

                <Col md={6} sm={12}>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 1 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1Start}
                        onChange={handleRound1Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 1 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round1End}
                        onChange={handleRound1End}
                      />
                    </Col>

                  </Row>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 2 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2Start}
                        onChange={handleRound2Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 2 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round2End}
                        onChange={handleRound2End}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 3 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3Start}
                        onChange={handleRound3Start}
                      />
                    </Col>
                    
                    <Col md={6}>
                      <p className="m-0 black-text">Round 3 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round3End}
                        onChange={handleRound3End}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p className="m-0 black-text">Round 4 Start at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4Start}
                        onChange={handleRound4Start}
                      />
                    </Col>
                    <Col md={6}>
                      <p className="m-0 black-text">Round 4 End at </p>
                      <CustomDateTimePicker
                        classes="mt-2 mb-3"
                        value={round4End}
                        onChange={handleRound4End}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={
                      updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form> */
}
