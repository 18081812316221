import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, DecimalInput, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import DraggableImageUploader from "../DraggableImageUpload/DraggableImageUploader";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editPriceGuessingModal.module.scss";
import styles from "../CustomComponents/index.module.css";

const EditPriceGuessingModal = ({
  handleEditModalClose,
  data,
  handleAdd,
  handleUpdate,
  updateLoading,
}) => {
  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      module_type: "price_guessing",
      question: "",
      description: "",
      time_limit: "10",
      status: "",
      image: "",
      min: "",
      max: "",
      price: "",
    },
  });

  const [flag, setFlag] = useState(false);

  const statusList = [
    { label: "Active", value: "active" },
    { label: "InActive", value: "inactive" },
  ];

  useEffect(() => {
    if (data) {
      setValue("status", data.status);
      setValue("question", data.question);
      setValue("description", data.description);
      // setValue("time_limit", data.time_limit);
      setValue("image", data.image_url);
      setValue("min", data?.question_options[0]?.option);
      setValue("max", data?.question_options[1]?.option);
      setValue("price", data?.price);
      setFlag(!flag);
    }
  }, [data]);

  return (
    <ModalContainer handleClose={handleEditModalClose}>
      <div className={cssStyles.modalContainer}>
        <div className="d-flex align-items-center w-100 justify-content-between mb-4">
          <p className="fs-4 fw-medium m-0 black-text">
            {data ? "Edit Price Guessing" : "Add Price Guessing"}
          </p>

          <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
            <FaCircleXmark
              role="button"
              onClick={handleEditModalClose}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="w-100">
          <Form onSubmit={handleSubmit(data ? handleUpdate : handleAdd)}>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">Question</p>
                    <Controller
                      control={control}
                      name="question"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="text"
                          placeholder="Enter question"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <p className="m-0 black-text">Description</p>
                    <Controller
                      control={control}
                      name="description"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="text"
                          placeholder="Enter description"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <p className="m-0 black-text">Status</p>
                    <Controller
                      control={control}
                      name="status"
                      value={getValues("status")}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          className={`${styles.Input} w-100`}
                          onChange={onChange}
                          value={getValues("status")}
                        >
                          {!!statusList?.length &&
                            statusList.map(({ label, value }, index) => (
                              <option key={index} value={value}>
                                {label}
                              </option>
                            ))}
                        </select>
                      )}
                    />
                  </Col>

                  {/* <Col lg={6}>
                    <p className="m-0 black-text">Time Limit</p>
                    <Controller
                      control={control}
                      name="time_limit"
                      rules={{}}
                      defaultValue={30}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          type="number"
                          placeholder="Enter time left"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <span className="gray-text fs-8">
                      (in seconds) by default 10 seconds
                    </span>
                  </Col> */}
                </Row>

                <Row className="mt-3">
                  <Col sm={12} md={6}>
                    <p className="m-0 black-text">Min Price</p>
                    <Controller
                      control={control}
                      name="min"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="number"
                          placeholder="Min price for guessing"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={12} md={6}>
                    <p className="m-0 black-text">Max Price</p>
                    <Controller
                      control={control}
                      name="max"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="number"
                          placeholder="Max price for guessing"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>

                  <Col sm={12} md={12}>
                    <p className="m-0 black-text">Actual Price</p>
                    <Controller
                      control={control}
                      name="price"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          classes="mt-2 mb-3"
                          type="number"
                          placeholder="Actual Price"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Button
                      classes={`${cssStyles.cardBtn} mt-4 ${
                        updateLoading ? "bg-dark-subtle" : ""
                      }`}
                      text={
                        updateLoading ? (
                          <BeatLoader size={10} />
                        ) : data ? (
                          "Update"
                        ) : (
                          "Save"
                        )
                      }
                      // onClick={handleSubmit}
                      type="Submit"
                    ></Button>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12}>
                    <Controller
                      control={control}
                      name="image"
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DraggableImageUploader
                          key={1}
                          mediaImage={value}
                          setMediaImage={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditPriceGuessingModal;
