import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import BeatLoader from "react-spinners/BeatLoader";
import {
  Button,
  CustomInput,
  Description,
  Title,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { useForgotPasswordMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";

export default function ForgotPassword({ setPage }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  // const isLoading = true;
  // console.log(isLoading, "isLoading");

  const [forgetPassword, { isError, isLoading, isSuccess }] =
    useForgotPasswordMutation();

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleDataSubmit = async (userData) => {
    console.log(userData);

    try {
      const response = await forgetPassword(userData);

      console.log(response);

      if (response.data.code === 200) {
        SuccessToast(response?.data?.message);

        navigate(WEB_ROUTES.ACCOUNT);
      }
    } catch (error) {
      // console.log(error.data.message, 'errrrr');
      if (error.data && error.data.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout
      hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors.email?.message
          ? Toast(errors.email?.message, "error")
          : null
        : null}
      <div className="card rounded-4 shadow-sm w-100 p-3">
        <Title text="Forgot Password" classes="mb-2 black-text text-center" />
        <Description
          text="In order to reset your password you need to enter your registered email address."
          classes="text-center fs-7 gray-text"
        />
        <Form onSubmit={handleSubmit(handleDataSubmit)} className="w-100">
          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email is required.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Enter a valid email address.",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup
                className="mb-3"
                // style={{
                //   width: "300px",
                // }}
              >
                <CustomInput
                  placeholder="Email"
                  type="email"
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                />
              </InputGroup>
            )}
          />
          <Button
            text={isLoading ? <BeatLoader size={10} /> : "Continue"}
            classes={`mt-3 mb-2 ${isLoading ? "bg-dark-subtle" : ""}`}
            disabled={isLoading}
            type="submit"
          />
        </Form>
      </div>
    </AccountLayout>
  );
}
