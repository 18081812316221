import React from "react";

const RecordNotFound = () => {
  return (
    <div style={{ height: "200px", alignContent: "center", fontSize: "20px" }}>
      Record not found
    </div>
  );
};

export default RecordNotFound;
